@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  text-align: center;
}

.App-logo {
  width: 135px;
  float: left;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.YouTube-logo img {
  width: 45px;
  float: right;
}

.YouTube-logo:hover {
  opacity: 0.7;
}

/* Style the navbar */
.Navbar,
.nav {
  background-color: #161515;
  display: flex;
  justify-content: center;
  float: right;
}

.Nav-container {
  width: 1024px;
}

.nav-items {
  margin: 0;
}

.nav-item-youtube {
  display: flex;
  padding: 0.7rem;
}

.hamburger-react {
  color: #cfd0d3;
  float: right;
  display: none;
}

.Nav-container button {
  background-color: transparent;
  border: none;
}

.nav-items-vertical {
  color: #cfd0d3;
  background-color: #161515;
  list-style: none;
  min-width: 120px;
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 3rem;
  position: absolute;
  z-index: 1000;
}

.nav-item-vertical {
  padding: 1rem;
}

.nav-item-vertical:hover {
  text-decoration: underline;
  color: #5b6279;
  cursor: pointer;
}

.nav-item-youtube-vertical {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

#Burger {
  right: -185px;
  transition: right 0.5s;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.sticky .nav-item {
  float: left;
  display: block;
  color: #cfd0d3;
  text-align: center;
  padding: 14px 14px 14px 14px;
  text-decoration: none;
  cursor: pointer;
}
.sticky .nav-item:hover {
  text-decoration: underline;
  color: #5b6279;
}

.Demo {
  width: 100%;
  min-height: 97vh;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3rem;
}

.App-about {
  background-color: #e7e8eb;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  color: #161515;
  padding: 2rem 5rem 2rem 5rem;
}

.column {
  padding: 2rem;
  width: 33.33%;
  min-width: 96px;
  min-height: 96px;
  top: 0;
  left: 0;
}

.App-gallery {
  background-color: #161515;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.masonry-wrapper {
  padding: 1.5em;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.react-photo-gallery--gallery img:hover {
  opacity: 0.5;
  transition: opacity 0.15s ease-in-out;
}

.App-footer {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.5vmin);
  color: #cfd0d3;
  padding: 1rem;
}

/*    ======================   @MEDIA QUERIES  ======================      */

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .masonry {
    columns: 2;
  }
}

@media only screen and (min-width: 1024px) {
  .masonry {
    columns: 3;
  }
}

@media only screen and (max-width: 965px) {
  .nav-items {
    display: none;
  }
  .hamburger-react {
    display: flex;
  }
  .App-logo {
    margin: auto;
    float: left;
  }
}

@media only screen and (max-width: 900px) {
  .App-about {
    flex-direction: column;
    padding: 1rem 2rem 1rem 2rem;
  }
  .column {
    padding: 1rem;
    width: auto;
  }
}

@media only screen and (max-width: 425px) {
  .Demo {
    min-height: 300px;
  }
}

@media only screen and (max-width: 320px) {
  .Demo {
    min-height: 200px;
  }
}
